export const dateValidator = (value: any) => {
  if (value === 'Invalid date') {
    return 'Please enter valid format - MM/DD/YYYY';
  } else return true;
};

export const timeValidator = (value: any) => {
  if (value === 'Invalid date') {
    return 'Please enter valid format - HH:MM AM/PM';
  } else return true;
};
