import { del } from 'idb-keyval';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { AUTH_CONFIG, pharmacyIdKey, REACT_QUERY_INDEX_DB_KEY } from '@linx-ui/shared/constants';
import { clearLocalStorage, clearSessionStorage, removeFromLocalStorage } from '@linx-ui/shared/utils';

export const useLogout = () => {
  const { logout, isAuthenticated } = useAuth0();

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: AUTH_CONFIG.redirectURL } });
  };

  useEffect(() => {
    return () => {
      void (async () => {
        if (!isAuthenticated) {
          clearSessionStorage();
          removeFromLocalStorage(pharmacyIdKey);
          clearLocalStorage();
          await del(REACT_QUERY_INDEX_DB_KEY);
        }
      })();
    };
  }, [isAuthenticated]);

  return handleLogout;
};
