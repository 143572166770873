import { Typography } from '@mui/material';
import type { FC } from 'react';
import { theme } from '@linx-ui/shared/theming';
import { MenuItemStyled } from '../styles';

export const UserMenuItem: FC<{ name?: string; type?: string }> = ({ name, type }) => (
  <MenuItemStyled cursor="auto">
    <Typography variant="body1" fontWeight="700">
      {name}
    </Typography>
    <Typography variant="body2" color={theme.palette.text.secondary}>
      {type}
    </Typography>
  </MenuItemStyled>
);
