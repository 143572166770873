import { useQueryClient } from '@tanstack/react-query';
import { type PaginatedResult } from '@linx-ui/shared/types';
import { getHeadersFromKeys } from '../../../utils';
import { useReactMutation } from '../useReactMutation';
import type { useDeleteMutationParams } from './types';

export const useDeleteMutation = ({
  onMutate,
  listQueryParams = {},
  useOptimisticUpdate = true,
  ...config
}: useDeleteMutationParams) => {
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys(config.headerKeys ?? []);

  const itemId = config.url.split('/').pop();
  const listUrl = config.url.replace(`/${itemId}`, '');
  const listQueryKey = [listUrl, headers, { limit: -1, offset: 0, ...listQueryParams }];

  return useReactMutation<never>({
    ...config,
    method: 'DELETE',
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: listQueryKey });
      const previousData = queryClient.getQueryData(listQueryKey);

      if (previousData && useOptimisticUpdate) {
        queryClient.setQueryData(listQueryKey, (previous: PaginatedResult<{ id?: string }>) => ({
          items: previous.items.filter((item) => item.id !== itemId),
          metaData: {
            offset: previous.metaData.offset,
            count: previous.metaData.count - 1
          }
        }));
      }

      useOptimisticUpdate && setTimeout(() => onMutate?.(variables), 0);
      return { previousData };
    },
    onError: (_error, _variables, context: any) => {
      context.previousData && queryClient.setQueryData(listQueryKey, context.previousData);
    },
    onSettled: (data, error, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: [listUrl, headers] });
      config?.onSettled?.(data, error, variables, context);
    }
  });
};
