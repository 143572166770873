import { getToken } from 'firebase/messaging';
import { useEffect } from 'react';
import { urls } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { isLinxAdmin } from '@linx-ui/shared/utils';
import { messaging } from './app';

export const useFirebaseRegisterClient = () => {
  const { auth } = useGlobalContext();
  const linxAdmin = isLinxAdmin(auth);

  const { mutate } = useReactMutation({
    url: urls.registerFirebaseClient,
    method: 'POST'
  });

  useEffect(() => {
    messaging &&
      !linxAdmin &&
      getToken(messaging, { vapidKey: process.env.FIREBASE_VAPID_KEY })
        .then((currentToken) => mutate({ token: currentToken }))
        .catch((error) => console.log('Unable to fetch the token', error));
  }, []);
};
