import { Sections } from '../sections';
import { ShortcutIds } from '../shortcutIds';
import type { ShortcutConfig } from '../types';

export const profilesSection: ShortcutConfig = [
  {
    id: ShortcutIds.CREATE_COMPOUND_DRUG,
    description: 'Create Compound Drug',
    shortcut: ['alt', 'shift', 'KeyC'],
    trigger: true,
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles/drugs'
  },
  {
    id: ShortcutIds.CREATE_NEW_PRESCRIBER,
    description: 'Create New Prescriber',
    shortcut: ['alt', 'shift', 'KeyP'],
    trigger: true,
    section: Sections.PROFILE,
    enableFor: '/rx/pharmacy-sc/profiles/prescribers'
  }
];
