import { ArrowBack } from '@mui/icons-material';
import { Grid, Skeleton, Tab, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ActionRenderer } from '@linx-ui/shared/components/actionRenderer';
import { GridLayoutItem } from '@linx-ui/shared/components/gridLayout';
import { type NavigationItem } from '@linx-ui/shared/components/navigation';
import {
  BackAndDetails,
  BackIconWrapper,
  ButtonStyled,
  Content,
  ContentContainer,
  Footer,
  OutletCnt,
  SubTitle,
  TabsStyled,
  Title
} from '@linx-ui/shared/components/styles';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { useDynamicHeight } from '@linx-ui/shared/hooks/useDynamicHeight';
import { useRouteMatch } from '@linx-ui/shared/hooks/useRouteMatch';
import { theme } from '@linx-ui/shared/theming';
import { type RecentHistory } from '@linx-ui/shared/types';
import { type DetailsProps } from './types';
import { UpdateDetails } from './UpdateDetails';

function Details<T>({
  navItems,
  backRoute,
  backButtonText,
  getTitle,
  getSubTitle,
  TitleChip,
  additionalInfo,
  getTitleChipLabels,
  timeStampDetails: { hasTimestamp, hasUser, timestamp } = {
    hasTimestamp: true,
    hasUser: true,
    timestamp: (data) => (data as any)?.recentHistory as RecentHistory
  },
  actions,
  setScrollIndex,
  data,
  refreshDetails,
  loading,
  isPlaceholderData,
  featureName,
  actionType = 'read'
}: DetailsProps<T>) {
  const hasPermissionToRead = useFeaturePermissions()(featureName)(actionType);
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState<string | null>();
  const [topRef, topHeight] = useDynamicHeight();
  const currentTab = useRouteMatch(navItems.map((el) => el.absRoute));
  const backBtn = { backRoute, backButtonText, ...(location.state ?? {}) };

  const scrollIndexes: Record<string, number> = useMemo(
    () =>
      navItems.reduce((acc: any, current: NavigationItem) => {
        return { ...acc, [current.absRoute]: current.scrollIndex };
      }, {}),
    []
  );

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  const goBack = () => navigate(`${backBtn.backRoute}${backBtn.search ?? ''}`);

  useEffect(() => {
    setScrollIndex?.(scrollIndexes[value!]);
  }, [value]);

  if (!hasPermissionToRead) {
    return null;
  }

  return (
    <ContentContainer>
      <Content>
        <div ref={topRef}>
          <BackAndDetails>
            <Grid container>
              <GridLayoutItem xs={4} md={4} alignItems="center">
                {backBtn.backButtonText && (
                  <BackIconWrapper container spacing={2} alignItems="center">
                    <GridLayoutItem alignItems="center">
                      <ButtonStyled onClick={goBack} data-testid="back-btn">
                        <ArrowBack color="secondary" fontSize="small" />
                        {backBtn.backButtonText}
                      </ButtonStyled>
                    </GridLayoutItem>
                  </BackIconWrapper>
                )}
                <GridLayoutItem container alignItems="center">
                  <GridLayoutItem xs={12} container>
                    <GridLayoutItem xs={12}>
                      <GridLayoutItem xs={12} gap="10px" container alignItems="center">
                        {!loading ? (
                          <Tooltip title={getTitle(data)} placement="bottom-start">
                            <Title>{getTitle(data)}</Title>
                          </Tooltip>
                        ) : (
                          <Skeleton width={300} />
                        )}

                        {TitleChip &&
                          (!loading ? (
                            <TitleChip labels={getTitleChipLabels?.(data, additionalInfo) ?? []} />
                          ) : (
                            <Skeleton sx={{ ml: theme.spacing(2.5) }} width={180} />
                          ))}
                      </GridLayoutItem>
                      {getSubTitle ? (
                        !loading ? (
                          <Tooltip title={getSubTitle(data)} placement="bottom-start">
                            <SubTitle>{!loading ? getSubTitle(data) : <Skeleton width={500} />}</SubTitle>
                          </Tooltip>
                        ) : (
                          <Skeleton width={300} />
                        )
                      ) : null}
                    </GridLayoutItem>
                  </GridLayoutItem>
                </GridLayoutItem>
              </GridLayoutItem>
              <GridLayoutItem xs={8} md={8} alignItems="center">
                {actions && !value?.includes('history') && <ActionRenderer actions={actions} />}
              </GridLayoutItem>
            </Grid>
          </BackAndDetails>
          {navItems.length > 0 && (
            <TabsStyled value={value}>
              {navItems.map(({ key, title, route, absRoute, getIcon }) => {
                return (
                  <Tab
                    key={key}
                    label={title}
                    value={absRoute}
                    component={Link}
                    to={route}
                    state={location.state}
                    icon={getIcon ? getIcon(data, value === absRoute, additionalInfo) : undefined}
                    iconPosition="end"
                  />
                );
              })}
            </TabsStyled>
          )}
        </div>
        <OutletCnt topHeight={topHeight}>
          <Outlet
            context={{
              data,
              refreshDetails,
              additionalInfo,
              loading,
              isPlaceholderData
            }}
          />
          {!value?.includes('history') && (hasTimestamp ?? hasUser) && (
            <Footer>
              <Grid container justifyContent="end">
                <GridLayoutItem>
                  {!loading ? (
                    <UpdateDetails hasTimestamp={!!hasTimestamp} hasUser={!!hasUser} data={timestamp?.(data)} />
                  ) : (
                    <Skeleton width={300} />
                  )}
                </GridLayoutItem>
              </Grid>
            </Footer>
          )}
        </OutletCnt>
      </Content>
    </ContentContainer>
  );
}

export { Details };
