import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CircularLoader, isLinxAdmin } from '@linx-ui/shared';
import { useGlobalContext } from '@linx-ui/shared/context';

const AdminApp = React.lazy(
  async () => await import(/* webpackChunkName: "Admin" */ /* webpackPrefetch: true */ './admin/AdminApp')
);

const PharmacistSelector = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "PharmacistSelector" */ /* webpackPrefetch: true */ '@linx-ui/pharmacy/pharmacistSelector'
    )
);

const PharmacyApp = React.lazy(
  async () => await import(/* webpackChunkName: "Pharmacy" */ /* webpackPrefetch: true */ './pharmacy/PharmacyApp')
);

const NotFound = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "NotFound" */ /* webpackPrefetch: true */ '@linx-ui/shared/components/notFound/NotFound.component'
    )
);

const RefetchFromServer = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "RefetchFromServer" */ /* webpackPrefetch: true */ '@linx-ui/shared/components/refetchFromServer/RefetchFromServer'
    )
);

const DownloadFile = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "DownloadFile" */ /* webpackPrefetch: true */ '@linx-ui/shared/components/downloadFile/DownloadFile'
    )
);

const AppRoutes = (): React.ReactElement => {
  const { auth } = useGlobalContext();
  const linxAdmin = isLinxAdmin(auth);
  const defaultRoute = linxAdmin ? 'admin-sc' : 'pharmacy-sc';

  return (
    <Suspense fallback={<CircularLoader />}>
      <Routes>
        <Route index element={<Navigate to="rx" />} />
        <Route path="pos/*" element={<RefetchFromServer />} />
        <Route path="rx">
          <Route index element={<Navigate to={defaultRoute} />} />
          <Route path="admin-sc/*" element={linxAdmin ? <AdminApp /> : <Navigate to="/rx/pharmacy-sc" />} />
          <Route path="pharmacy-sc/*" element={<PharmacyApp />} />
          <Route path="reports/download/:id" element={<DownloadFile />} />
          <Route path="pharmacist-selector" element={<PharmacistSelector />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
