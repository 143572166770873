import { IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactQuery } from '@linx-ui/shared/hooks/api/useReactQuery';
import { useDeleteWrapper } from '@linx-ui/shared/hooks/useDeleteWrapper';
import { useDownloadFile } from '@linx-ui/shared/hooks/useDownloadFile';
import { theme } from '@linx-ui/shared/theming';
import { getDisplayDateTimeWithSlashFormatter } from '@linx-ui/shared/utils';
import { type ListItemProps } from '../types';
import {
  CancelIconCnt,
  CreatedBy,
  DeleteIcon,
  DownloadIcon,
  FileIndicatorIcon,
  FileInfoCnt,
  FileName,
  ListItemCnt,
  ProgressCnt,
  SmallCircularProgress
} from './ListItem.styled';

const ListItem = ({
  fileName,
  folderName,
  recentHistory,
  headerKeys,
  url,
  entityId,
  id,
  onDeleteSuccess
}: ListItemProps) => {
  const [view, setView] = useState(false);
  const { deleteEntity, isDeleting } = useDeleteWrapper({
    url: `${url}/${entityId}/${folderName}/${id}`,
    successToastMsg: 'Deleted successfully!',
    onDelete: onDeleteSuccess,
    headerKeys
  });

  const {
    data: fileDownloadUrl,
    isLoading: loadingDownloadUrl,
    refetch: fetchFileDownloadUrl
  } = useReactQuery<string>({
    url: `${url}/${entityId}/${folderName}/${id}`,
    headerKeys,
    enabled: false
  });

  const uploadedByInfo = useMemo(() => {
    return `Uploaded by ${recentHistory.createdByUser} on ${getDisplayDateTimeWithSlashFormatter(
      recentHistory.createdTime
    )}`;
  }, [recentHistory]);

  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    deleteEntity({});
  }, []);

  const { download, downloading } = useDownloadFile({ url: fileDownloadUrl, fileName });

  const canDownload = useMemo(
    () => !loadingDownloadUrl && fileDownloadUrl && typeof fileDownloadUrl === 'string',
    [loadingDownloadUrl, fileDownloadUrl]
  );

  useEffect(() => {
    if (canDownload) {
      if (!view) {
        void download();
      } else {
        window.open(fileDownloadUrl);
      }
    }
  }, [fileDownloadUrl, canDownload, view]);

  const onDownload = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setView(false);
    void fetchFileDownloadUrl();
  }, []);

  const onView = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setView(true);
    void fetchFileDownloadUrl();
  }, []);

  return (
    <ListItemCnt onClick={onView}>
      <FileInfoCnt>
        <FileIndicatorIcon fontSize="large" />
        <ProgressCnt>
          <FileName>{fileName}</FileName>
          <CreatedBy>{uploadedByInfo}</CreatedBy>
        </ProgressCnt>
      </FileInfoCnt>
      <CancelIconCnt>
        <IconButton onClick={onDownload} disabled={loadingDownloadUrl}>
          {downloading ? (
            <SmallCircularProgress size={theme.spacing(3)} />
          ) : (
            <DownloadIcon data-testid="download-file-icon" />
          )}
        </IconButton>
        <IconButton onClick={onDelete} disabled={isDeleting}>
          {isDeleting ? (
            <SmallCircularProgress size={theme.spacing(3)} />
          ) : (
            <DeleteIcon data-testid="delete-file-icon" />
          )}
        </IconButton>
      </CancelIconCnt>
    </ListItemCnt>
  );
};

export { ListItem };
