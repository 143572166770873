import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { ChangeHistoryTableCnt } from '@linx-ui/shared/components/styles';
import { Table } from '@linx-ui/shared/components/table';
import { TABLE_NAMES } from '@linx-ui/shared/constants/tableNames';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';
import { columns } from './columns';
import type { History, Props } from './types';

export const ChangeHistory = memo(({ url, headerKeys = [] }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useReactQueryList<History>({
    url: `${url}/${id}/history`,
    headerKeys
  });

  return (
    <ChangeHistoryTableCnt>
      <Table
        name={TABLE_NAMES.CHANGE_HISTORY}
        testId="test-change-history"
        columns={columns}
        data={data.items}
        loading={isLoading}
        enableGlobalFilter
      />
    </ChangeHistoryTableCnt>
  );
});
ChangeHistory.displayName = 'ChangeHistory';

export default ChangeHistory;
