import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  Auth0ContextProvider,
  GlobalContextProvider,
  KeyboardShortcutsContextProvider,
  ReactQueryPersisterContextProvider,
  ShortcutsQuickFind,
  theme
} from '@linx-ui/shared';
import App from './App';

if ('serviceWorker' in navigator) {
  const serviceWorkerUrl = process.env.NODE_ENV === 'production' ? `/service-worker.js` : `/firebase-messaging-sw.js`;
  navigator.serviceWorker
    .register(serviceWorkerUrl)
    .then((registration) => console.log('SW registered: ', registration))
    .catch((registrationError) => console.log('SW registration failed: ', registrationError));
}

if ('Notification' in window) {
  Notification.requestPermission()
    .then((permission) => console.log(`Notification permission ${permission}.`))
    .catch(() => console.log('Unable to request permission for notifications.'));
}

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <Auth0ContextProvider>
        <ReactQueryPersisterContextProvider>
          <GlobalContextProvider>
            <KeyboardShortcutsContextProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles
                  styles={{
                    html: { width: '100%', height: '100%' },
                    body: {
                      backgroundColor: theme.palette.background.paper,
                      width: '100%',
                      height: '100%'
                    },
                    '#root': {
                      height: '100%',
                      width: '100%'
                    }
                  }}
                />
                <ShortcutsQuickFind />
                <App />
              </ThemeProvider>
            </KeyboardShortcutsContextProvider>
          </GlobalContextProvider>
        </ReactQueryPersisterContextProvider>
      </Auth0ContextProvider>
    </BrowserRouter>
  );
}
