import { TextField, styled } from '@mui/material';

export const NumberTextField = styled(TextField)(() => ({
  '& input::-webkit-outer-spin-button , & input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  }
}));
