export const PRESCRIPTION_STATUSES = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'On Hold', value: 'ON_HOLD' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Transferred', value: 'TRANSFERRED' },
  { label: 'Incomplete', value: 'INCOMPLETE' }
];

export const PRESCRIPTION_STATUS_LABELS: Record<string, string> = Object.fromEntries(
  PRESCRIPTION_STATUSES.map(({ label, value }) => [value, label])
);

export const statusLabels: Record<string, string> = {
  TO_BE_BILLED: 'To be billed',
  CONTACT_FOR_INSURANCE: 'Contact for insurance',
  CONTACT_FOR_REFILL: 'Contact for refill',
  PRIOR_AUTHORIZATION_REQUIRED: 'Prior authorization required',
  PRIOR_AUTHORIZATION_IN_PROGRESS: 'Prior authorization in progress',
  INSUFFICIENT_QUANTITY: 'Insufficient quantity',
  TO_BE_REVERSED: 'To be reversed',
  FINANCIAL_ASSISTANCE: 'Financial assistance',
  TO_BE_FILLED: 'To be filled',
  FOLLOW_UP_WITH_PRESCRIBER: 'Follow up with prescriber',
  FOLLOW_UP_WITH_PATIENT: 'Follow up with patient',
  TO_BE_VERIFIED: 'To be verified',
  TO_BE_DELIVERED: 'To be delivered',
  TO_BE_PICKED_UP: 'To be picked up',
  OUT_FOR_DELIVERY: 'Out for delivery',
  DELIVERED: 'Delivered',
  PICKED_UP: 'Picked up',
  SELECT_PATIENT: 'Select Patient',
  SELECT_PRESCRIBER: 'Select Prescriber',
  SELECT_DRUG: 'Select Drug',
  SET_RX_DETAIL: 'Set Rx Details',
  REJECTION: 'Rejection',
  DUR: 'DUR',
  UPCOMING_REFILL: 'Upcoming Refill'
};

export const queueLabels: Record<string, string> = {
  INTAKE: 'Intake',
  BILLING: 'Billing',
  FILLING: 'Filling',
  VERIFICATION: 'Verification',
  READY: 'Ready',
  COMPLETE: 'Complete'
};
