import { Popover, styled } from '@mui/material';
import { theme } from '@linx-ui/shared/theming';

const StyledPopover = styled(Popover)<{ type?: string }>(({ type }) => ({
  pointerEvents: type === 'hover' ? 'none' : 'auto',
  padding: theme.spacing(2)
}));

const StyledImg = styled('img')(() => ({
  cursor: 'pointer'
}));

const StyledInfoImg = styled('img')(() => ({
  display: 'block'
}));

export { StyledImg, StyledInfoImg, StyledPopover };
