import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';
import { numberTextFieldChangeHandler } from '@linx-ui/shared/utils';
import { NumberTextField } from '../numberTextField';
import type { TextFieldWrapperProps } from './types';

const TextFieldWrapper = (props: TextFieldWrapperProps): JSX.Element => {
  const Component = useMemo(() => (props.type === 'number' ? NumberTextField : TextField), [props.type]);
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ?? ''}
      render={({ field: { value, onBlur, onChange, ...rest } }) => (
        <Component
          data-testid={props.dataTestId}
          type={props.type}
          size={props.size}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          value={value || ''}
          InputProps={{ ...props.inputProps, disableInjectingGlobalStyles: true }}
          InputLabelProps={props.InputLabelProps}
          {...rest}
          onChange={
            props.customOnChange
              ? props.customOnChange?.(onChange)
              : props.type === 'number'
                ? numberTextFieldChangeHandler(onChange)
                : onChange
          }
          onBlur={(e) => {
            onBlur();
            props.customOnBlur?.(e.target.value);
          }}
          multiline={props.multiline}
          rows={props.rows}
          onFocus={() => props.onFocus?.()}
          label={props.label}
          error={props.error?.hasError}
          helperText={props.error?.message ?? props?.helperText}
          variant={props.variant || 'outlined'}
          inputProps={{
            maxLength: props.rules?.maxLength,
            role: props.role
          }}
          fullWidth
          required={!!props.rules?.required}
        />
      )}
    />
  );
};
export { TextFieldWrapper };
