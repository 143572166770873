import type { AxiosRequestConfig } from 'axios';
import { useLocation } from 'react-router-dom';
import { pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';
import type { User } from '@linx-ui/shared/types';
import { usePharmacyId } from '../pharmacy';

export const useUsers = (params?: AxiosRequestConfig['params']) => {
  const { pathname } = useLocation();
  const isAdminDashboard = pathname.includes('admin-sc');
  const { pharmacyId } = usePharmacyId();

  const { data, ...restResult } = useReactQueryList<User>({
    url: urls.users,
    params,
    headerKeys: isAdminDashboard ? [] : [pharmacyIdKey],
    enabled: isAdminDashboard || !!pharmacyId
  });

  return {
    ...restResult,
    users: data.items,
    isLoading: restResult.isFetching && (data?.items?.length || 0) === 0
  };
};
