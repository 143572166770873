import type { FC } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { AutocompleteWrapper, type AutoCompleteWrapperProps } from '../autocompleteWrapper';

interface Option {
  label: string;
  value: string;
}

export const AutocompleteSelectWrapper: FC<AutoCompleteWrapperProps<Option>> = (props) => {
  return (
    <AutocompleteWrapper<Option>
      {...props}
      onChange={(data: Option) => {
        return props.onChange ? props.onChange(data) : data?.value || '';
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter(
          (option) =>
            option.label?.toLowerCase().includes(params.inputValue.toLowerCase()) ||
            option.value?.toLowerCase().includes(params.inputValue.toLowerCase())
        );

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.find((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: inputValue,
            label: `Add "${inputValue}"`
          });
        }

        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error?.hasError}
          onFocus={() => props.onFocus?.()}
          helperText={props.error?.message}
          label={props.label}
          required={!!props.rules?.required}
          inputProps={{
            ...params.inputProps,
            value:
              props.options.find((option: Option) => option.value === params.inputProps.value)?.label ||
              params.inputProps.value
          }}
          InputProps={{
            ...params.InputProps,
            disableInjectingGlobalStyles: true,
            endAdornment: (
              <>
                {props.loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
