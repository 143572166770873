import { ShortcutIds } from '../shortcutIds';
import { Sections } from '../sections';
import type { ShortcutConfig } from '../types';

const QUEUE_ROUTE = '/rx/pharmacy-sc/queues';

export const QueueSection: ShortcutConfig = [
  {
    id: ShortcutIds.OPEN_INTAKE_QUEUE,
    description: 'Navigate to the Intake Queue',
    quickFindDescription: 'Queues > Intake Queue',
    shortcut: ['ctrl', 'KeyI'],
    navigate: '/rx/pharmacy-sc/queues/intake',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_BILLING_QUEUE,
    description: 'Navigate to the Billing Queue',
    quickFindDescription: 'Queues > Billing Queue',
    shortcut: ['ctrl', 'KeyB'],
    navigate: '/rx/pharmacy-sc/queues/billing',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_FILLING_QUEUE,
    description: 'Navigate to the Filling Queue',
    quickFindDescription: 'Queues > Filling Queue',
    shortcut: ['ctrl', 'KeyF'],
    navigate: '/rx/pharmacy-sc/queues/filling',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_VERIFICATION_QUEUE,
    description: 'Navigate to the Verification Queue',
    quickFindDescription: 'Queues > Verification Queue',
    shortcut: ['ctrl', 'KeyE'],
    navigate: '/rx/pharmacy-sc/queues/verification',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_READY_QUEUE,
    description: 'Navigate to the Ready Queue',
    quickFindDescription: 'Queues > Ready Queue',
    shortcut: ['ctrl', 'KeyR'],
    navigate: '/rx/pharmacy-sc/queues/ready',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  },
  {
    id: ShortcutIds.OPEN_UPCOMING_REFILLS_QUEUE,
    description: 'Navigate to the Upcoming Refills Queue',
    quickFindDescription: 'Queues > Upcoming Refills Queue',
    shortcut: ['ctrl', 'KeyU'],
    navigate: '/rx/pharmacy-sc/queues/upcoming-refills',
    section: Sections.QUEUE,
    enableFor: QUEUE_ROUTE
  }
];
