import { useNavigate } from 'react-router-dom';
import { useDeleteMutation } from '../api/useDeleteMutation';
import { type UseDeleteWrapperProps } from './types';

export const useDeleteWrapper = ({
  url,
  redirectTo,
  successToastMsg,
  successToastTitle,
  headerKeys,
  onDelete,
  listQueryParams,
  useOptimisticUpdate = true
}: UseDeleteWrapperProps) => {
  const navigate = useNavigate();

  const onDone = (id: string) => {
    onDelete?.(id);
    redirectTo && navigate(redirectTo);
  };
  const callback = useOptimisticUpdate ? { onMutate: onDone } : { onSuccess: onDone };

  const { mutate: deleteEntity, isPending: isDeleting } = useDeleteMutation({
    url,
    headerKeys,
    listQueryParams,
    useOptimisticUpdate,
    successTitle: successToastTitle,
    successMsg: successToastMsg,
    ...callback
  });

  return { deleteEntity, isDeleting };
};
