import { ALPHA_NUMERIC, IPV4_ADDRESS, IPV6_ADDRESS, SIX_DIGITS, TEN_DIGITS, WORDS } from '../utils/validations/regex';

export const requiredValidationRule = {
  required: { value: true, message: 'This field is required' }
};

export const stringValidationRule = {
  pattern: { value: WORDS, message: 'Please enter valid string' }
};

export const positiveNumberValidationRule = {
  validate: (value: string) => (value ? (Number(value) > 0 ? null : 'Please enter positive number') : null)
};

export const positiveWholeNumberValidationRule = {
  validate: (value: string) => {
    return value
      ? Number.isInteger(Number(value))
        ? positiveNumberValidationRule.validate(value)
        : 'Please enter whole number'
      : null;
  }
};

export const ipAddressValidationRules = {
  required: { value: true, message: 'Please enter IP address' },
  validate: (value: string) =>
    IPV4_ADDRESS.test(value) || IPV6_ADDRESS.test(value) ? null : 'Please enter valid IP address'
};

export const nameValidationRules = {
  minLength: 1,
  maxLength: 75,
  required: { value: true, message: 'Please enter Name' },
  ...stringValidationRule
};

export const npiValidationRules = {
  required: { value: true, message: 'Please enter NPI#' },
  maxLength: 10,
  minLength: { value: 10, message: 'NPI number should be 10 digit' },
  pattern: {
    value: TEN_DIGITS,
    message: 'Can’t contain a text or symbol'
  }
};

export const binValidationRules = {
  required: { value: true, message: 'Please enter BIN#' },
  maxLength: 6,
  minLength: { value: 6, message: 'BIN# should be 6 digit' },
  pattern: {
    value: SIX_DIGITS,
    message: 'Can’t contain a text or symbol'
  }
};

export const pcnValidationRules = {
  minLength: 1,
  maxLength: 10,
  required: false,
  pattern: {
    value: ALPHA_NUMERIC,
    message: 'Please enter a valid PCN#'
  }
};
