import { withAuthenticationRequired } from '@auth0/auth0-react';
import { CircularLoader } from '@linx-ui/shared';
import { useFirebaseRegisterClient } from '@linx-ui/shared/core/firebase/useFirebaseRegisterClient';
import AppRoutes from './AppRoutes';

const App: React.FC = () => {
  useFirebaseRegisterClient();

  return <AppRoutes />;
};

const AppWithAuthentication = withAuthenticationRequired(App, {
  onRedirecting: () => <CircularLoader data-testid="progress" />
});

export default AppWithAuthentication;
