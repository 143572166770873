import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { CancelAction } from '@linx-ui/shared/components/Actions';
import { Container } from '@linx-ui/shared/components/container';
import { DrawerContainer } from '@linx-ui/shared/components/drawerContainer';
import { Documents } from '@linx-ui/shared/components/notesAndDocuments';
import { DRAWER_WIDTH_XSM } from '@linx-ui/shared/constants';
import { theme } from '@linx-ui/shared/theming';
import { type FileUploadDrawerProps } from './types';

export const FileUploadDrawer = ({
  url,
  entityId,
  headerKeys = [],
  acceptedFiles = { '*': [] },
  folderName,
  onUpload,
  onSubmitBtnClick,
  onCancelCallback,
  triggerBtn,
  showUploadedFiles = false,
  drawerTitleTxt,
  uploadContainerTitleTxt,
  uploadTitleTxt,
  uploadSubTitleTxt = 'Only 810 File with max size of 10 MB',
  fileTypeErrorMsg = 'Only 810 file types are allowed.',
  submitButtonTxt,
  cancelButtonTxt,
  featureName,
  multiple
}: FileUploadDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [result, setResult] = useState<Array<{ id: string }>>();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    !isDrawerOpen && onCancelCallback?.();
  }, [isDrawerOpen]);

  const resetStates = () => {
    setResult(undefined);
    setDisabled(false);
  };

  const toggle = () =>
    setIsDrawerOpen((open) => {
      !!open && resetStates();
      return !open;
    });

  const handleOnUpload = (result: Array<{ id: string }>) => {
    setResult(result);
    !multiple && setDisabled(true);
    onUpload?.(result);
  };

  const actions = [
    <CancelAction key="cancel" onClick={toggle} btnText={cancelButtonTxt} />,
    <Button
      key="add"
      variant="contained"
      data-testid="submit-btn"
      disabled={!disabled}
      onClick={() => {
        toggle();
        onSubmitBtnClick?.(result);
      }}
    >
      {submitButtonTxt}
    </Button>
  ];

  return (
    <DrawerContainer
      triggerBtn={triggerBtn}
      openDrawer={isDrawerOpen}
      toggle={toggle}
      actionBtnsRight={actions}
      title={drawerTitleTxt}
      width={DRAWER_WIDTH_XSM}
    >
      <Container
        title={uploadContainerTitleTxt}
        style={{ margin: theme.spacing(1), height: '100%' }}
        contentStyle={{ height: '100%' }}
      >
        <Documents<Array<{ id: string }>>
          url={url}
          entityId={entityId}
          headerKeys={headerKeys}
          onUpload={handleOnUpload}
          showUploadedFiles={showUploadedFiles}
          folderName={folderName}
          fileInputProps={{
            disabled,
            accept: acceptedFiles,
            fileTypeErrorMsg,
            title: uploadTitleTxt,
            subTitle: uploadSubTitleTxt
          }}
          featureName={featureName}
        />
      </Container>
    </DrawerContainer>
  );
};
