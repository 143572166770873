import { useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { getHeadersFromKeys } from '@linx-ui/shared/utils';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import type { PaginatedResult } from '@linx-ui/shared/types';
import type { Notification } from '../types';

export const useNotificationUpdateStatus = (notificationId: string, notificationType: string) => {
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys([pharmacyIdKey]);
  const queryKeys = [
    [urls.notifications, headers, { type: null, isArchived: false }],
    [urls.notifications, headers, { type: notificationType, isArchived: false }]
  ];

  const { mutate } = useReactMutation({
    url: urls.notificationStatus(notificationId),
    method: 'PATCH',
    headerKeys: [pharmacyIdKey],
    onMutate: async () => {
      await Promise.all(queryKeys.map(async (queryKey) => await queryClient.cancelQueries({ queryKey })));
      const previousData = queryKeys.map((queryKey) => queryClient.getQueryData(queryKey));
      queryKeys.forEach((queryKey) =>
        queryClient.setQueryData<InfiniteData<PaginatedResult<Notification>>>(queryKey, (data) => ({
          pageParams: data?.pageParams || [],
          pages:
            data?.pages.map((page) => {
              let isPageContainsNotificationToUpdate = false;
              const items = page.items.map((item) => {
                if (item.id === notificationId) {
                  isPageContainsNotificationToUpdate = true;
                  return { ...item, isRead: true };
                }
                return item;
              });
              return isPageContainsNotificationToUpdate
                ? {
                    ...page,
                    items,
                    metaData: {
                      ...page.metaData,
                      unreadCount: page.metaData.unreadCount! - 1
                    }
                  }
                : page;
            }) || []
        }))
      );
      return { previousData };
    },
    onError: (_error, _variables, context: any) => {
      queryKeys.forEach((queryKey, index) => queryClient.setQueryData(queryKey, context.previousData[index]));
    }
  });

  return mutate;
};
